import localforage from './web_modules/localforage.js'
import './web_modules/localforage-observable.js'
import { Observable } from './web_modules/rxjs.js'

localforage.ready().then(() => {
    localforage.configObservables({
        crossTabNotification: true,
        crossTabChangeDetection: true,
    })
    localforage.newObservable.factory = function (subscribeFn) {
        return Observable.create(subscribeFn)
    }
})

export { localforage }

export * as jose from './node_modules/jose/dist/browser/index.js'

export { createId } from './web_modules/@paralleldrive/cuid2.js'

export * as firebaseAuth from './web_modules/@firebase/auth.js'
export * as firebaseApp from './web_modules/@firebase/app.js'
export * as firebaseDatbase from './web_modules/@firebase/database.js'

import yaml from './web_modules/yaml.js'
export { yaml }

import slugify from './web_modules/slugify.js'
export { slugify }

import QRious from './web_modules/qrious.js'
export { QRious }

export * as webdav from './webdav.js'
